<template>
  <div class="">
    <!-- Kitchen -->
    <h4 class="mb-3"><u>{{msg('Kitchen')}}</u></h4>
    <b-row>
      <b-col cols="12" sm="3" md="3" lg="2" class="mb-2" v-for="item in kitchenItems" :key="item.key">
          <i :class="item.icon" class="mr-1"></i>
          <span class="item-name me-2">{{ msg(item.key) }}</span>
          <b-form-spinbutton
              v-model="missingResources[item.key]"
              min="0"
              max="200"
              step="1"
              size="sm"
              class="mx-2 w-75"
              @change="onQuantityChange(item.key)"
          ></b-form-spinbutton>
      </b-col>
    </b-row>

    <!-- Bathroom -->
    <h4 class="mb-3"><u>{{msg('Bathroom')}}</u></h4>
    <b-row>
      <b-col cols="12" sm="3" md="3" lg="2" class="mb-2" v-for="item in bathroomItems" :key="item.key">
          <i :class="item.icon" class="mr-1"></i>
          <span class="item-name me-2">{{ msg(item.key) }}</span>
          <b-form-spinbutton
              v-model="missingResources[item.key]"
              min="0"
              max="100"
              step="1"
              size="sm"
              class="mx-2 w-75"
              @change="onQuantityChange(item.key)"
          ></b-form-spinbutton>
      </b-col>
    </b-row>

    <!-- Living Room -->
    <h4 class="mb-3"><u>{{msg('Living Room')}}</u></h4>
    <b-row>
      <b-col cols="12" sm="3" md="3" lg="2" class="mb-2" v-for="item in livingRoomItems" :key="item.key">
          <i :class="item.icon" class="mr-1"></i>
          <span class="item-name me-2">{{ msg(item.key) }}</span>
          <b-form-spinbutton
              v-model="missingResources[item.key]"
              min="0"
              max="100"
              step="1"
              size="sm"
              class="mx-2 w-75"
              @change="onQuantityChange(item.key)"
          ></b-form-spinbutton>
      </b-col>
    </b-row>

    <!-- Bedroom -->
    <h4 class="mb-3"><u>{{msg('Bedroom')}}</u></h4>
    <b-row>
      <b-col cols="12" sm="3" md="3" lg="2" class="mb-2" v-for="item in bedroomItems" :key="item.key">
          <i :class="item.icon" class="mr-1"></i>
          <span class="item-name me-2">{{ msg(item.key) }}</span>
          <b-form-spinbutton
              v-model="missingResources[item.key]"
              min="0"
              max="100"
              step="1"
              size="sm"
              class="mx-2 w-75"
              @change="onQuantityChange(item.key)"
          ></b-form-spinbutton>
      </b-col>
    </b-row>
  </div>
</template>


<script>
export default {
  name: "cleaningResources",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },  data() {
    return {

      kitchenItems: [
        {key: "platesSmall", icon: "fas fa-plate-wheat"},
        {key: "platesBig", icon: "fas fa-plate-wheat"},
        {key: "smallPan", icon: "fas fa-bacon"},
        {key: "bigPan", icon: "fas fa-bacon"},
        {key: "smallPot", icon: "fas fa-kitchen-set"},
        {key: "bigPot", icon: "fas fa-kitchen-set"},
        {key: "coffeeMachine", icon: "fas fa-coffee"},
        {key: "coffeeCapsules", icon: "fas fa-capsules"},
        {key: "smallChocolates", icon: "fas fa-candy-cane"},
        {key: "forks", icon: "fas fa-utensils"},
        {key: "knives", icon: "fas fa-utensils"},
        {key: "spoon", icon: "fas fa-utensil-spoon"},
        {key: "microwave", icon: "fas fa-microchip"},
        {key: "kettle", icon: "fas fa-beer"},
        {key: "cup", icon: "fas fa-mug-hot"},
        {key: "teacup", icon: "fas fa-coffee"},
      ],
      bathroomItems: [
        {key: "hairdryer", icon: "fas fa-wind"},
        {key: "washingMachine", icon: "fas fa-water"},
        {key: "showerhead", icon: "fas fa-shower"},
        {key: "toiletPaper", icon: "fas fa-toilet-paper"},
        {key: "smallTowel", icon: "fas fa-shower"},
        {key: "bigTowel", icon: "fas fa-shower"},
      ],
      livingRoomItems: [
        {key: "tv", icon: "fas fa-tv"},
        {key: "internet", icon: "fas fa-wifi"},
        {key: "deskChairs", icon: "fas fa-chair"},
        {key: "sofa", icon: "fas fa-couch"},
        {key: "curtains", icon: "fas fa-vectors-square"},
        {key: "rug", icon: "fas fa-rug"},
      ],
      bedroomItems: [
        {key: "bed", icon: "fas fa-bed"},
        {key: "bedlinen", icon: "fas fa-bed"},
        {key: "bedcover", icon: "fas fa-bed"},
        {key: "pillows", icon: "fas fa-bed"},
        {key: "iron", icon: "fas fa-tshirt"},
      ],
    };
  },
  computed: {
    missingResources: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onQuantityChange(key) {
      this.$emit("update:resources", {...this.missingResources});
    },
  },
};
</script>

<style>
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  max-width: 250px;
  margin-bottom: 15px;
}

h4 {
  margin-top: 20px;
}
</style>
